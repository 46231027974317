import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/home",
      component: resolve => require(["@/components/home.vue"], resolve),
      children: [
        // 首页
        {
          path: "/home",
          name: "home",
          component: resolve => require(["@/views/home/index.vue"], resolve)
        },
        // 居民管理
        {
          path: "/resident-manage",
          name: "resident-manage",
          component: resolve =>
            require(["@/views/resident-manage/index.vue"], resolve)
        },
        // 小组管理
        {
          path: "/group-manage",
          name: "group-manage",
          component: resolve =>
            require(["@/views/group-manage/index.vue"], resolve)
        },
        // 社区管理
        {
          path: "/community-manage",
          name: "community-manage",
          component: resolve =>
            require(["@/views/community-manage/index.vue"], resolve)
        },
        // 打卡管理
        {
          path: "/task-manage",
          name: "task-manage",
          component: resolve =>
            require(["@/views/task-manage/index.vue"], resolve)
        },
        // 个人排名
        {
          path: "/person-rank",
          name: "person-rank",
          component: resolve =>
            require(["@/views/person-rank/index.vue"], resolve)
        },
        // 小组排名
        {
          path: "/group-rank",
          name: "group-rank",
          component: resolve =>
            require(["@/views/group-rank/index.vue"], resolve)
        },
        // 个人统计
        {
          path: "/person-statics",
          name: "person-statics",
          component: resolve =>
            require(["@/views/person-statics/index.vue"], resolve)
        },
        // 小组统计
        {
          path: "/group-statics",
          name: "group-statics",
          component: resolve =>
            require(["@/views/group-statics/index.vue"], resolve)
        },
        // 附加积分计算
        {
          path: "/ntegral-calculation",
          name: "ntegral-calculation",
          component: resolve =>
            require(["@/views/ntegral-calculation/index.vue"], resolve)
        },
        // 试题管理
        {
          path: "/exam-question",
          name: "exam-question",
          component: resolve =>
            require(["@/views/exam-question/index.vue"], resolve)
        },
        // 学习模块
        {
          path: "/exam-study",
          name: "exam-study",
          component: resolve =>
            require(["@/views/exam-study/index.vue"], resolve)
        },
        // 试题标签管理
        {
          path: "/exam-label",
          name: "exam-label",
          component: resolve =>
            require(["@/views/exam-label/index.vue"], resolve)
        },
        // 试卷管理
        {
          path: "/exam-paper",
          name: "exam-paper",
          component: resolve =>
            require(["@/views/exam-paper/index.vue"], resolve)
        },
        // 考试任务下发
        {
          path: "/exam-task-send",
          name: "exam-task-send",
          component: resolve =>
            require(["@/views/exam-task-send/index.vue"], resolve)
        },
        // 考试任务分析
        {
          path: "/exam-task-analysis",
          name: "exam-task-analysis",
          component: resolve =>
            require(["@/views/exam-task-analysis/index.vue"], resolve)
        },
        // 医生考试任务下发
        {
          path: "/exam-doctor-send",
          name: "exam-doctor-send",
          component: resolve =>
            require(["@/views/exam-doctor-send/index.vue"], resolve)
        },
        // 商品分类
        {
          path: "/goods-type",
          name: "goods-type",
          component: resolve =>
            require(["@/views/goods-type/index.vue"], resolve)
        },
        // 商品管理
        {
          path: "/goods-manage",
          name: "goods-manage",
          component: resolve =>
            require(["@/views/goods-manage/index.vue"], resolve)
        },
        // 兑换记录
        {
          path: "/exchange-record",
          name: "exchange-record",
          component: resolve =>
            require(["@/views/exchange-record/index.vue"], resolve)
        },
        // 菜单设置
        {
          path: "/menu-settings",
          name: "menu-settings",
          component: resolve =>
            require(["@/views/menu-settings/index.vue"], resolve)
        },
        // 角色设置
        {
          path: "/role-settings",
          name: "role-settings",
          component: resolve =>
            require(["@/views/role-settings/index.vue"], resolve)
        },
        // 后台账号
        {
          path: "/account-settings",
          name: "account-settings",
          component: resolve =>
            require(["@/views/account-settings/index.vue"], resolve)
        },
        // 权限设置
        {
          path: "/power-settings",
          name: "power-settings",
          component: resolve =>
            require(["@/views/power-settings/index.vue"], resolve)
        },
        // 积分明细
        {
          path: "/taskScore-details",
          name: "taskScore-details",
          component: resolve =>
            require(["@/views/taskScore-details/index.vue"], resolve)
        },
        // 处方管理
        {
          path: "/recipel-manage",
          name: "recipel-manage",
          component: resolve =>
            require(["@/views/recipel-manage/index.vue"], resolve)
        },
        // 健康数据管理
        {
          path: "/health-record",
          name: "health-record",
          component: resolve =>
            require(["@/views/health-record/index.vue"], resolve)
        },
        // 用户建议
        {
          path: "/user-suggest",
          name: "user-suggest",
          component: resolve =>
            require(["@/views/user-suggest/index.vue"], resolve)
        },
        // 直播管理
        {
          path: "/live-manage",
          name: "live-manage",
          component: resolve =>
            require(["@/views/live-manage/index.vue"], resolve)
        },
        // 本院活动
        {
          path: "/discount",
          name: "discount",
          component: resolve => require(["@/views/discount/index.vue"], resolve)
        },
        // // 测试模块
        // {
        //   path: "/test",
        //   name: "test",
        //   component: resolve => require(["@/views/test/index.vue"], resolve)
        // },
        // 常见问题
        {
          path: "/question",
          name: "question",
          component: resolve => require(["@/views/question/index.vue"], resolve)
        }
      ]
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/components/login.vue"),
      meta: { title: "登录" }
    }
  ]
});
// 路由守卫
router.beforeEach((to, from, next) => {
  const isLogin = localStorage.getItem("eleToken");
  if (isLogin) {
    next();
    if (to.path === "/login") {
      next("/");
    }
  } else {
    // 如果用户token不存在则跳转到login页面
    if (to.path === "/login") {
      next();
    } else {
      next("/login");
    }
  }
});
const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};

export default router;
