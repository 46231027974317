import request from "./http";
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    request
      .get(url, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    request
      .post(url, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function del(url, data = {}) {
  return new Promise((resolve, reject) => {
    request
      .delete(url, { data: data })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装update请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function update(url, data = {}) {
  return new Promise((resolve, reject) => {
    request
      .put(url, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/**
 * 封装export get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function getExl(url, params) {
  return new Promise((resolve, reject) => {
    request
      .get(url, {
        params: params,
        responseType: "blob",
      })
      .then((response) => {
        console.log(response.data)
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/**
 * 封装export Post方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function exportExl(url, data) {
  return new Promise((resolve, reject) => {
    request
      .post(url, data,{
        responseType: "blob",
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
