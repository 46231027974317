import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userArea: [],
    userId: undefined,
    role: ""
  },
  mutations: {
    // 保存当前用户的地区信息
    saveUsername(state, userArea) {
      state.userArea = userArea;
    },
    // 保存当前用户的userId
    saveUserInfo(state, data) {
      state.userId = data.userId;
      state.role = data.role;
    }
  },
  actions: {},
  modules: {}
});
