import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/iconfont/iconfont.css'
import * as echarts from 'echarts'
import '@/utils/rem.js'
// 富文本组件
import Editor from "@/components/Editor/index.vue"
/* 全局分页组件 */
import newPagination from './components/new-pagination'

// 全局组件挂载
Vue.component('newPagination', newPagination)
Vue.component('Editor', Editor)

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(ElementUI, {
  size: 'small'
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
